import { Fragment, useCallback, useState } from "react";
import { WMSTileLayer, useMap } from "react-leaflet";

import { FeaturesLayer } from "./FeaturesLayer";
import { MAP_LAYER_TYPES } from "../constants";
import { RasterInformation } from "./RasterInformation/RasterInformation";
import { useKeyboardShortcut } from "../hooks/useKeyboardShortcut";
import { useSelector } from "react-redux";

export const TrialDisplay = () => {
  const map = useMap();

  const resultMap = useSelector(({ resultMap }) => resultMap);

  const fitZoomToSite = useCallback(() => {
    if (resultMap.trial?.bounding_box)
      //zoom on site bouding box if no result
      map?.fitBounds(resultMap.trial.bounding_box);
    else if (resultMap.trial?.trial_position)
      // zoom approx. on site if there is no bounding box
      map?.setView(resultMap.trial.trial_position, 18);
  }, [map, resultMap.trial.bounding_box, resultMap.trial.trial_position]);

  // Fit zoom to site on trial change only and on remount
  const [prevTrial, setPrevTrial] = useState(null);
  if (map && resultMap.trial !== prevTrial) {
    setPrevTrial(resultMap.trial);
    fitZoomToSite();
  }

  useKeyboardShortcut("c", fitZoomToSite);

  return resultMap.mapLayers
    .filter(({ visible }) => visible)
    .map((layer) => (
      <Fragment key={layer.name ?? "geojson"}>
        {layer.type === MAP_LAYER_TYPES.GEOJSON && <FeaturesLayer />}
        {layer.type === MAP_LAYER_TYPES.ORTHO && (
          <WMSTileLayer
            layers={
              resultMap.trial.ortho_layer[resultMap.trial_date][layer.name]
            }
            key={`${resultMap.trial.id}${resultMap.trial_date}${layer.name}`}
            url={process.env.REACT_APP_GEOSERVER_URL}
            format="image/png"
            transparent="true"
            opacity={layer.opacity}
            tiled={true}
            zIndex={1100 - layer.priority}
            maxZoom={50}
          />
        )}
        <RasterInformation />
      </Fragment>
    ));
};
